import React, { useEffect, useState } from 'react'
import axios from "../../axios";
import "./style.scss"
import { toast } from 'react-toastify';
import Overlay from '../../components/Overlay';
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions";
import { withRouter, useHistory } from "react-router-dom";

function DailyBooster({ userData }) {
  const [isLoading, setIsLoading] = useState(false);
  // console.log("userData", userData)
  const permissions = userData?.data?.modules?.find((item) => item.moduleName === "Reward Management")?.permissions;
  console.log(permissions)
  const [formData, setFormData] = useState({
    ball: { id: "678ba921d09e1b2767933dde", value: "", rewards: 0 },
    arrow: { id: "678ba951d09e1b2767933ddf", value: "", rewards: 0 },
    spin: { id: "678ba96ad09e1b2767933de0", value: "" }
  });

  const fetchRewardValue = async (key) => {
    try {
      setIsLoading(true)
      const res = await axios.get(`/findRewardValueById/${formData[key].id}`);
      const { value, rewards } = res.data.data[0];
      setFormData((prev) => ({
        ...prev,
        [key]: { ...prev[key], value, rewards },
      }));
      toast.success(res.data.message);
      setIsLoading(false)

    } catch (error) {
      console.log(error);
    }
  };

  console.log(formData?.ball?.rewards)

  const updateRewardValue = async (key) => {
    try {
      const res = await axios.post("/private/setRewardType", {
        ...formData[key],
        rewards: Number(formData[key].rewards),
      });
      // toast.success(res.data.message);
      fetchRewardValue(key);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchRewardValue("ball");
    fetchRewardValue("arrow");
    fetchRewardValue("spin")
  }, []);


  return (
    <>
      <div className='hewe-cont'>
        <div className='referralcont'>
          <div className='flex-align-center'>
            <div className="dott me-3"></div>
            <p className='main-heading'>Daily Booster</p>
          </div>
          <div className='ps-4'>
            <div className='desc my-4'>
              <p>Set daily limits for Arrow Booster, Ball Booster, and Spin the Wheel. Customize the number of attempts to enhance user participation and retention.</p>
            </div>
            <div className='notes my-4'>
              <p><strong>Note: </strong>Please be aware that any updates will take effect from {(() => {
                const today = new Date();
                today.setDate(today.getDate() + 1);
                return today.toLocaleDateString('en-GB');
              })()}, 12:00 AM midnight.</p>
            </div>
            <div className="refcont">
              <div className="row">
                <div className="col-3 text-center">
                  <h3>Type</h3>
                </div>
                <div className="col-3 text-center">
                  <h3>Total Attempt (per day)</h3>
                </div>
                <div className="col-3 text-center">
                  <h3>HEWE Coins (per Attempt)</h3>
                </div>
                <div className="col-2 text-center"></div>
              </div>

              {["ball", "arrow", "spin"].map((key) => (
                <div className="row mt-4" key={key}>
                  <div className="col-3 text-center">
                    <div className="card-type">{key.charAt(0).toUpperCase() + key.slice(1)} Booster</div>
                  </div>
                  <div className="col-3 text-center">
                    <input
                      type="text"
                      placeholder="Enter attempts"
                      value={formData[key].value}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          [key]: { ...prev[key], value: e.target.value },
                        }))
                      }
                    />
                  </div>
                  {/* Conditionally render the HEWE Coins input */}
                  {key !== "spin" && (
                    <div className="col-3 text-center">
                      <input
                        type="text"
                        placeholder="Enter Hewe Coins"
                        value={formData[key].rewards}
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            [key]: { ...prev[key], rewards: e.target.value },
                          }))
                        }
                      />
                    </div>
                  )}
                </div>
              ))}

              <div className="row mt-4 justify-content-center">
                <div className="col-2 text-center">
                  {(userData.userType === "admin" || userData.userType == "subAdmin" && permissions?.edit == true) &&
                    <button
                      onClick={() => {
                        ["ball", "arrow", "spin"].forEach((key) => updateRewardValue(key));
                      }}
                    >
                      Update
                    </button>
                  }
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
      {isLoading && <Overlay />}

    </>
  )
}



const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    locationData: state.locations,
    defaultState: state.defaultState,
    sidebar: state.sidebar,
  };
};

export default connect(
  mapStateToProps,
  // mapDispatchToProps
)(withRouter(DailyBooster));