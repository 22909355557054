import React, { useEffect, useState } from "react";
import axios from "../../axios";
import "./style.scss";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
} from "@material-ui/core";
import { withRouter, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import AllReward from "../../components/reward/AllReward/AllReward";
import Voucher from "../../components/reward/voucher/Vouchers";
import InAppReward from "../../components/reward/inAppReward/appReward";
import AuditToken from "../../components/reward/auditToken/AuditToken";
import Premium from "../../components/reward/premium/Premium";
import Trip from "../../components/reward/trip/trip";

import { connect } from "react-redux";
import * as actionTypes from "../../store/actions";


const useStyles = makeStyles((theme) => ({
    paperTableHeight: {
        width: "95%",
        marginLeft: "2rem",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        border: "1px solid #D9E3D9",
        overflowX: "scroll",
    },
    tableMainContainer: {
        overflowX: "scroll",
        borderTopLeftRadius: "4px",
        borderTopRightRadius: "4px",
    },
    tableHeadingRow: {
        // backgroundColor: "#21AFE6",
    },
    tablseHeadingCell: {
        padding: "0.8rem 20px !important",
        textAlign: "center",
        fontSize: "14px",
        color: "#264503",
        fontWeight: 600,
        borderBottom: "1px solid #D9E3D9",
        whiteSpace: "nowrap",
    },
    textMiddle: {
        padding: "0.6rem 20px !important",
        verticalAlign: "middle !important",
        textAlign: "center",
        fontSize: "14px",
        color: "#001050",
    },
    tablePaginationStyle: {
        borderTop: "1px solid #0000001a",
        borderRadius: "0rem 0rem 0.4rem 0.4rem",
        overflowY: "hidden",
    },
    button: {
        textAlign: "center",
        backgroundColor: "#88AE40",
        padding: "7px 30px",
        color: "white",
        width: "250px",
        border: "none",
        borderRadius: "4px"
    },
}));
function OtherReward({userData}) {
    
    const spinReward = sessionStorage.getItem("spinReward");
    const [stateValue, setStateValue] = useState(spinReward ? parseInt(spinReward) : 0);
  // console.log("userData", userData)
  const permissions = userData?.data?.modules?.find((item) => item.moduleName === "Reward Management")?.permissions;
  console.log(permissions)
    useEffect(() => {
        const savedValue = sessionStorage.getItem('spinReward');
        if (savedValue) {
            setStateValue(parseInt(savedValue));
        }
    }, []);

    const handleCardClick = (value) => {
        setStateValue(value);
        sessionStorage.setItem('spinReward', value);
    };

    const cardItems = [
        { label: 'All Reward', value: 0 },
        { label: 'Vouchers', value: 1 },
        { label: 'In App', value: 2 },
        { label: 'Audit Token', value: 3 },
        { label: 'Premium', value: 4 },
        { label: 'Trip', value: 5 }
    ];

    return (
        <>
            <div className="hewe-cont">
                <div className="referralcont">
                    <div className="flex-align-center">
                        <div className="dott me-3"></div>
                        <h3 className="font-dark-green mb-0 fs-22 main-heading">Manage Reward Category</h3>
                    </div>
                    <div className="rewardcardcont">
                        <div className="row flex-wrap">
                            {cardItems.map((item, index) => (
                                <div key={index} className="col-xl-2 col-lg-3 col-md-4 col-6 mb-2">
                                    <div
                                        className="cardcont"
                                        onClick={() => handleCardClick(item.value)}
                                        style={
                                            stateValue === item.value
                                                ? { backgroundColor: '#599265E0', color: 'white' }
                                                : { backgroundColor: '#EFEFEF', color: "#212121" }
                                        }
                                    >
                                        <h4>{item.label}</h4>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    {stateValue === 0 ? (
                        <AllReward userData={userData} />
                    ) : stateValue === 1 ? (
                        <Voucher userData={userData}  />
                    ) : stateValue === 2 ? (
                        <InAppReward  userData={userData} />
                    ) : stateValue === 3 ? (
                        <AuditToken  userData={userData} />
                    ) : stateValue === 4 ? (
                        <Premium userData={userData} />
                    ) : stateValue === 5 ? (
                        <Trip userData={userData} />
                    ) : null
                    }
                </div>
            </div>
        </>
    );
}



const mapStateToProps = (state) => {
    return {
      userData: state.userData,
      locationData: state.locations,
      defaultState: state.defaultState,
      sidebar: state.sidebar,
    };
  };
  
  export default connect(
    mapStateToProps,
    // mapDispatchToProps
  )(withRouter(OtherReward));
