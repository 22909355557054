import React, { useEffect, useState } from 'react'
import { DashHeading } from '../Authentican/Profile/ProfileElements'
import { FaEye, FaPlus } from 'react-icons/fa'
import { useHistory, withRouter, } from 'react-router-dom/cjs/react-router-dom';
import axios from "../../axios";
import Overlay from '../../components/Overlay';
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions";


import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { get } from 'lodash';
import { RiEdit2Fill, RiEditLine } from 'react-icons/ri';
import { toast } from 'react-toastify';
const useStyles = makeStyles((theme) => ({
  paperTableHeight: {
    width: "100%",
    minHeight: "50vh",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    border: "1px solid #D9E3D9",
    overflowX: "scroll",
  },
  tableMainContainer: {
    overflowX: "scroll",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
  },
  tableHeadingRow: {
    // backgroundColor: "#21AFE6",
  },
  tablseHeadingCell: {
    textTransform: "capitalize",
    padding: "0.8rem 20px !important",
    textAlign: "center",
    fontSize: "14px",
    color: "#264503",
    fontWeight: 600,
    borderBottom: "1px solid #D9E3D9",
    whiteSpace: "nowrap",
  },
  textMiddle: {
    padding: "0.6rem 20px !important",
    verticalAlign: "middle !important",
    textAlign: "center",
    fontSize: "14px",
    color: "black",
  },
  tablePaginationStyle: {
    borderTop: "1px solid #0000001a",
    borderRadius: "0rem 0rem 0.4rem 0.4rem",
    overflowY: "hidden",
  },
  sticky: {
    position: "sticky",
    left: "0"
  }
}));
function SubAdmin({ userData }) {
  // console.log("userData", userData)
  const permissions = userData?.data?.modules?.find((item) => item.moduleName === "SubAdmin Management")?.permissions;
  console.log(permissions)
  const history = useHistory();

  const [tableData, setTableData] = useState()
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false)
  const getData = async () => {
    try {

      const { data } = await axios.get("/private/getSubAdmin")
      setTableData(data?.data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getData()
  }, [])

  console.log(tableData)

  const funBlock = async (id, payload) => {
    try {
      setIsLoading(true)
      const data = await axios.put(`private/undateInfo/${id}?status=${payload}`)
      toast.success(data?.data?.message);
      getData()
      setIsLoading(false)
    } catch (error) {
      console.log(error)

    }
  }
  const funCancel = async (id, payload) => {
    try {
      setIsLoading(true)
      const data = await axios.put(`private/undateInfo/${id}?invitation=${payload}`)
      getData()
      toast.success(data?.data?.message);
      setIsLoading(false)
    } catch (error) {
      console.log(error)

    }
  }
  const funInvitation = async (id, payload) => {
    try {
      setIsLoading(true)

      const data = await axios.put(`private/undateInfo/${id}?invitation=${payload}`)
      getData()
      toast.success(data?.data?.message);
      setIsLoading(false)
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <div className='hewe-cont subadmincont'>
      <div className='flex-align-center  justify-content-between'>
        <div className="flex-align-center mb-0 ">
          <div className="dott me-3"></div>
          <DashHeading>Sub Admin Management</DashHeading>
        </div>
        <div>
          <button onClick={() => history.push(`/sub-admin-management/add-subAdmin`)} className='savebtn flex-align-center'><FaPlus className='me-2' />Add SubAdmin</button>
        </div>
      </div>
      <Paper className='mt-4'>
        <TableContainer className={classes.tableMainContainer}>
          <Table>
            <TableHead>
              <TableRow className={classes.tableHeadingRow}>
                <TableCell className={classes.tablseHeadingCell}>S.no</TableCell>
                <TableCell className={classes.tablseHeadingCell}>Status</TableCell>
                <TableCell className={classes.tablseHeadingCell}>Action</TableCell>
                <TableCell className={classes.tablseHeadingCell}>Sub Admin ID</TableCell>
                <TableCell className={classes.tablseHeadingCell}>Sub Admin Title</TableCell>
                <TableCell className={classes.tablseHeadingCell}>Sub Admin Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData?.map((category, index) => (
                <TableRow >
                  <TableCell className={classes.textMiddle}>
                    <div>{index + 1}</div>
                  </TableCell>
                  {(userData.userType === "admin" || userData.userType == "subAdmin" && permissions?.edit == true) ?
                    <TableCell className={classes.textMiddle}>
                      {category?.invitationAccepted == false && category?.isExpire === true ? (
                        <select name="invitationStatus"
                          onChange={(e) => {
                            const selectedValue = e.target.value;
                            if (selectedValue === "resend") {
                              funInvitation(category?.userId, 1);
                            }
                          }}
                          className='invitExpire subbtngrop' id="invitationStatus">
                          <option value="expire" selected>
                            Expire
                          </option>
                          <option value="resend">ReSend Invitation</option>
                        </select>
                      ) : category?.isInvitationCancel === true ? (
                        <select name="invitationStatus"
                          onChange={(e) => {
                            const selectedValue = e.target.value;
                            if (selectedValue === "resend") {
                              funInvitation(category?.userId, 1);
                            }
                          }}
                          className='invitExpire subbtngrop' id="invitationStatus">
                          <option value="expire" selected>
                            Invitation Cancel
                          </option>
                          <option value="resend">ReSend Invitation</option>
                        </select>
                      ) : category?.invitationAccepted === false ? (
                        <select
                          name="invitationStatus"
                          className="invitSent subbtngrop"
                          id="invitationStatus"
                          onChange={(e) => {
                            const selectedValue = e.target.value;
                            if (selectedValue === "cancel") {
                              funCancel(category?.userId, 2);
                            }
                          }}
                        >
                          <option value="sent" selected>Invitation Sent</option>
                          <option value="cancel">Cancel Invitation</option>
                        </select>
                      ) : category?.status === "2" ? (
                        <div className='blockbtn subbtngrop' onClick={() => funBlock(category?.userId, 1)}>
                          Blocked
                        </div>
                      ) : category?.status === "1" ? (
                        <div className='unblockbtn subbtngrop' onClick={() => funBlock(category?.userId, 2)}>
                          UnBlocked
                        </div>
                      ) : null}
                    </TableCell> : <TableCell className={classes.textMiddle}>
                      N/A
                    </TableCell>
                  }
                  <TableCell className={classes.textMiddle}>
                    {(userData.userType === "admin" || userData.userType == "subAdmin" && permissions?.edit == true) ?
                      <span onClick={() => history.push(`/sub-admin-management/edit-subAdmin/${category.userId}`)}>
                        <RiEdit2Fill size={22} color='rgba(94, 171, 7, 1)' />
                      </span> : "N/A "
                    }

                    {(userData.userType === "admin" || userData.userType == "subAdmin" && permissions?.view == true) ?
                      <span className='ms-2'>
                        <FaEye size={22} color='rgba(94, 171, 7, 1)' />
                      </span> : "N/A "
                    }
                  </TableCell>
                  <TableCell className={classes.textMiddle}>
                    {get(category, "subAdminId", "N/A")}
                  </TableCell>
                  <TableCell className={classes.textMiddle}>
                    {get(category, "subAdminTitle", "N/A")}
                  </TableCell>
                  <TableCell className={classes.textMiddle}>
                    {get(category, "subAdminName", "N/A")}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

    </div>
  )
}


const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    locationData: state.locations,
    defaultState: state.defaultState,
    sidebar: state.sidebar,
  };
};

export default connect(
  mapStateToProps,
  // mapDispatchToProps
)(withRouter(SubAdmin));
